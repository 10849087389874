import React from "react";
import { Col } from "react-bootstrap";
import { window } from "window-or-global";

export default function AccountDetails(props) {
  const {user} = props;
  const [model, supportID] = (window.atob(user.id) || "").split(":");

  return (
    <Col xs={12} xl={4} className="mb-3">
      <div className="panel-container h-100">
        <div className="panel-header">{"Account Details"}</div>
        <div className="panel-body h-100">
          <div className="user-details border-bottom-0">
            <div className="user-label">{"Building"}</div>
            <div className="user-info">{user?.unitNumber || ""}{user?.unitNumber ? " - " : ""}{user?.building?.name}</div>

            <div className="user-label">{"Status"}</div>
            <div className="user-info">{user?.disabled ? "Inactive" : "Active"}</div>

            <div className="user-label">{"Support ID"}</div>
            <div className="user-info">{`${supportID}`}</div>
          </div>
        </div>
      </div>
    </Col>
  );
}
