import React, { Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useAsyncSetState } from "use-async-setstate";
import moment from "moment-mini";

import ChangePlan from "../../components/portal/change-plan";
import CancelPlan from "../../components/portal/cancel-plan";
import UpdateAccount from "./update-account";
import { NON_INTERNET_PRODUCT_TYPES_TO_SHOW } from "../../utils/constants";

const PlanInformation = (props) => {
  const { user } = props;
  const [showChangePlan, setShowChangePlan] = useAsyncSetState(false);
  const [showUpdateCC, setShowUpdateCC] = useAsyncSetState(null);
  const [selectedCancelOrder, setCancelOrder] = useAsyncSetState(null);

  let planEnd, planStart, totalPeriod, remainingPeriod, percentPeriod;
  const { activeOrders = [], activePlan } = user;

  if (user.activePlan !== null) {
    planStart = moment(user?.activePlan?.start, "YYYY-MM-DD HH:mm:ssZ");
    planEnd = moment(user?.activePlan?.end, "YYYY-MM-DD HH:mm:ssZ");
    totalPeriod = planEnd.diff(planStart, "hours");
    remainingPeriod = planEnd.diff(moment(), "hours");

    percentPeriod = 100 - parseInt((remainingPeriod / totalPeriod) * 100) || 0;
    percentPeriod = percentPeriod > 100 ? 100 : percentPeriod;
  }

  return (
    <Fragment>
      {!!showChangePlan && (
        <ChangePlan
          user={user}
          selectedOrder={showChangePlan}
          show
          onClose={() => setShowChangePlan(null)}
        />
      )}
      {!!selectedCancelOrder && (
        <CancelPlan
          user={user}
          selectedCancelOrder={selectedCancelOrder}
          show
          onClose={async () => setCancelOrder(null)}
        />
      )}
      {!!showUpdateCC && (
        <UpdateAccount
          user={user}
          show
          onClose={() => setShowUpdateCC(false)}
        />
      )}
      <Col md={12} lg={8} className="mb-3">
        <div className="panel-container h-100">
          <div className="panel-header">Your Plan Information</div>
          <div className="panel-body h-100">
            <div className="plan">
              <Orders
                activePlan={activePlan}
                activeOrders={activeOrders.filter(ao => ao.orderItems?.edges?.length > 0)} // Filter plans without order item
                user={user}
                selectedCancelOrder={selectedCancelOrder}
                setCancelOrder={async (data) => setCancelOrder(data)}
                setShowChangePlan={async (data) => setShowChangePlan(data)}
              />
              <Row className="mt-3">
                <Col xs={8} />
                <Col xs={12} md={4} lg={4} className="text-center">
                  <a
                    onClick={async () => setShowUpdateCC(true)}
                    className="vw-button btn btn-black d-block ml-auto px-4 py-2"
                  >
                    {"Update Credit card"}
                  </a>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Col>
    </Fragment>
  );
};

const Orders = ({
  activeOrders = [],
  user,
  setCancelOrder,
  setShowChangePlan,
  activePlan,
}) => {
  return activeOrders.map((activeOrder) => {
    const internetOrderItem = (activeOrder?.orderItems?.edges.find(a=>a?.node?.product?.type === "internet"))?.node;
    const nonInternetOrderItems = activeOrder?.orderItems?.edges
      .filter(oi => oi.node?.product?.type !== "internet")
      .filter(oi => !oi.node?.product?.once) // filters items that are paid once
      .filter(oi => NON_INTERNET_PRODUCT_TYPES_TO_SHOW.includes(oi.node?.product?.type))
      .map(foi => foi.node);

    return (
      <Row key={activeOrder.id} className="border-bottom-grey mb-3 pb-3">
        <Col md={8} sm={12}>
          <InternetOrder
            internetOrderItem={internetOrderItem}
          />
          <OrderItem
            activeOrder={activeOrder}
            user={user}
            orderItems={nonInternetOrderItems}
          />
          <div className="plan-payment vw-portal-account-automatic">
            <Container>
              <Row>
                <Col xs="auto" className="pl-0">
                  <i className="fas fa-credit-card" style={{ fontSize: 40 }} />
                </Col>
                <Col>
                  <Row className="renewal-status">
                    {(() => {
                      switch (activeOrder?.status) {
                        case undefined:
                          return `Inactive Account`;
                        case 1:
                        case 2:
                          return `Cancelled Account`;
                        case 3:
                          return "Cancelling Account";
                        case 6:
                          return "Provisioning";
                        default:
                          return "Automatic Payments";
                      }
                    })()}
                  </Row>
                  <Row className="plan-desc" style={{ fontSize: 10 }}>
                    {internetOrderItem && getPlanDescription(
                      user,
                      (activeOrder?.orderItems?.edges || []).find(({node: orderItem}) => !orderItem?.product?.once && orderItem?.product?.type === "internet")?.node?.product || {value: 0},
                      activeOrder?.status,
                      (activeOrder?.orderItems?.edges || []).reduce((prev, value) => {
                        if (!value?.node?.product?.once) {
                          return Number(value?.node?.product?.value || 0) + prev;
                        }

                        return prev;
                      }, 0),
                      activePlan,
                    )}
                    {!internetOrderItem && <Fragment>
                      <div>
                        {`You will be charged $${nonInternetOrderItems.reduce((prev, curr) => prev + parseFloat(curr?.product?.value), 0)} 
                          for your selected plan by automatic fund transfer on ${moment(activeOrder.renewsAt).format("LL")}`}
                      </div>
                    </Fragment>}
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
        <Col md={4} sm={12}>
          {activeOrder?.status !== 6 && (
            <a
              onClick={async () => setShowChangePlan(activeOrder)}
              className="vw-button btn btn-black mb-2 d-block ml-auto px-4 py-2"
            >
              {"Change Plan"}
            </a>
          )}
          {activeOrder.status >= 4 && activeOrder.status !== 6 && (
            <a
              onClick={async () => setCancelOrder(activeOrder)}
              className="vw-button btn btn-black w-100 px-4 py-2"
            >
              {"Cancel Order"}
            </a>
          )}
        </Col>
      </Row>
    );
  });
}

const ActivePlan = ({activePlan}) => {
  if (!activePlan) {
    return <Fragment/>;
  }
  const product = activePlan?.product;

  return (
    <div className="plan-title" key={activePlan.id}>
      {`${product?.name || "No Active Plan"}${
        product?.rateProfile?.name ? " - " : ""
      }${product?.rateProfile?.name || ""}`}
    </div>
  );
}

const InternetOrder = ({internetOrderItem}) => {
  if (!internetOrderItem) {
    return <Fragment/>;
  }
  const product = internetOrderItem?.product;
  return (
    <div className="plan-title" key={internetOrderItem.id}>
      {`${product?.name || "No Active Plan"}${
        product?.rateProfile?.name ? " - " : ""
      }${product?.rateProfile?.name || ""}`}
    </div>
  );
};

const OrderItem = ({orderItems = [], activeOrder, user}) => {
  return (
    <section className="order-items_section">
      {orderItems.map(orderItem => {
        const product = orderItem?.product;
        return (
          <div className="order-item-title" key={orderItem.id}>
            {`${product?.name || "No Active Plan"}${
              product?.rateProfile?.name ? " - " : ""
            }${product?.rateProfile?.name || ""}`}
          </div>
        );
      })}
    </section>
  );
};

const getPlanDescription = (user, product, status, total, activePlan) => {
  const planEnd = moment(user?.activePlan?.end);
  if (user?.scheduledRenewal) {
    let scheduledProduct = (user?.scheduledRenewal.products || [])[0] || {};

    return `Your plan will change to ${scheduledProduct.description} for $${
      scheduledProduct.value
    } on ${planEnd.format("Do of MMMM YYYY")}.`;
  }
  if (activePlan?.product.id !== product.id) {
    const planEnd = moment(activePlan?.end);
    return `Your plan will change to ${product.description} for $${
      total
    } on ${planEnd.format("Do of MMMM YYYY")}.`;
  }
  switch (status) {
    case undefined:
      return "You do not have an active plan.";
    case 1:
    case 2:
      return "Your plan is inactive";
    case 3:
      return `Your plan will be disabled on the ${planEnd.format(
        "Do of MMMM YYYY"
      )}`;
    case 6:
      return product.description;
    default:
      return `Your next automatic payment for plan ${activePlan.product.description} will be $${
        total || 0
      } on ${planEnd.format("Do of MMMM YYYY")}.`;
  }
}

export default PlanInformation;
