import React, { useState } from "react";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import {
  useMutation,
} from "@apollo/client";
import { localStorage, window } from "window-or-global";

import {logoutMutation} from "../logic/user";
import logoCircle from "../../images/getgud-logo.svg";
import Main from "../main";
import {Fade as Burger} from "../react-burger"
import LogoutIcon from "../../images/logout.svg"
import AuthIcon from "../../images/icon_authenticator.svg"

export default function Index(props) {
  return (
    <Main>
      <Header {...props} />
    </Main>
  )
}

function Header() {
  const [showMenu, setShowMenu] = useState(false);
  const [logout] = useMutation(logoutMutation);

  async function onHandleLogout() {
    await logout();
    localStorage.removeItem("authToken");
    return window.location = "/login";
  }

  return (
    <header className="bg-grey portal-header">
      <Container>
        <Navbar bg="grey" variant="dark" expand="lg">
          <Navbar.Brand href="/">
            <img src={logoCircle} alt="logo" className="header-logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <Burger direction="right"/>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto align-items-center">
              <Nav.Link href="/portal">Home</Nav.Link>
              <Nav.Link href="/portal">Contact Us</Nav.Link>
              <Nav.Link href="/portal">Need Help?</Nav.Link>
              <NavDropdown menuAlign={{lg: "right"}} className="portal-header-dropdown" title={<Burger direction="right"/>} onClick={() => setShowMenu(prev => !prev)} show={showMenu}>
                <NavDropdown.Item href="/mfa"><img src={AuthIcon}/>{"2-Step Verification"}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => onHandleLogout()}><img src={LogoutIcon}/>{"Logout"}</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link className="ph-link hidden-on-expand" href="/mfa">{"2-Step Verification"}</Nav.Link>
              <Nav.Link className="ph-link hidden-on-expand" onClick={() => onHandleLogout()}>{"Logout"}</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </header>
  );
}
