import React, {useState, Fragment} from "react";
// import { getProducts } from "../../../logic/products";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ToggleComponent from "../../components/toggle";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { getFormData } from "../../components/manager/form";
import { getProductsQuery, getProductsResult } from "../../components/logic/products";
import { useQuery } from "@apollo/client";
import { NON_INTERNET_PRODUCT_TYPES_TO_SHOW } from "../../utils/constants";

export default function AddonsDisplay(props) {
  const {addons = [], onAddProduct, onRemoveProduct, voucherCode, readOnly, disableHardWare = false} = props;
  const data = getFormData();
  const result = useQuery(getProductsQuery, {
    variables: {
      buildingCode: data?.building?.code,
      voucherCode:  voucherCode || data?.voucherCode,
      showHidden: true
    }
  });

  // console.log("result", result);
  if (result.loading) {
    return (<React.Fragment/>);
  }

  const products = getProductsResult(result);
  // console.log("products", products);


  if (products.length === 0) {
    return (<div/>);
  }

  function bindOnToggleClick(product, active) {
    if (readOnly) {
      return undefined;
    }
    return (e) => {
      if(active) {
        return onRemoveProduct(product);
      }
      return onAddProduct(product)
    }
  }
  const genericAddons = products.filter((p) => NON_INTERNET_PRODUCT_TYPES_TO_SHOW.indexOf(p.type) > -1);
  const hardwareAddons = products.filter((p) => p.type === "hardware");

  function ToggleControl({p, handleClick, active}) {
    return (<Col xs={12} xl={6} key={p.id} className="no-wrap">
      <ToggleComponent onChange={handleClick} checked={active} />
      <span onClick={handleClick}>
        {`${p.description} (+$${p.value}${p.type !== "hardware" ? "/Month" : " once off"}) `}
        {(p.extraInfo && p.extraInfo !== "") && (<OverlayTrigger
          placement={"right"}
          overlay={
            <Tooltip>
              <div dangerouslySetInnerHTML={{__html: p.extraInfo}} />
            </Tooltip>
          }
          >
          <i className="far fa-question-circle" />
        </OverlayTrigger>)}
      </span>
    </Col>)
  }


  return (<Container fluid>
    {genericAddons.length > 0 && (
    <Fragment>
      <Row>
        <Col xs="auto" className="mx-auto">
          <h3>{"Available Addons"}</h3>
        </Col>
      </Row>
      <Row className="align-items-center">
        {genericAddons
          .map(((p) => {
            const active = addons.filter((a) => a.id === p.id).length > 0
            const handleClick = bindOnToggleClick(p, active);
            return (<ToggleControl key={p.id} p={p} handleClick={handleClick} active={active} />);
          }))}
      </Row>
    </Fragment>)}
    {!disableHardWare && hardwareAddons.length > 0 ? (<React.Fragment>
      <Row>
        <Col xs="auto" className="mx-auto">
          <h3>{"Optional Hardware"}</h3>
        </Col>
      </Row>
      <Row className="align-items-center">
        {hardwareAddons
          .map(((p) => {
            const active = addons.filter((a) => a.id === p.id).length > 0
            const handleClick = bindOnToggleClick(p, active);
            return (<ToggleControl key={p.id} p={p} handleClick={handleClick} active={active} />);
          }))}
      </Row>
    </React.Fragment>) : undefined}
  </Container>);
}

