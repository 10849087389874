import React from "react";
import {v4} from "uuid";

export default function ToggleComponent(props) {
  const id = `cc${v4()}`
  return (<div class="custom-control my-1 custom-switch">
    <input
      type="checkbox"
      class="custom-control-input"
      id={id}
      checked={props.checked}
      onChange={props.onChange}
    />
    <label class="custom-control-label" for={id}>
      {props.children}
    </label>
  </div>);
}