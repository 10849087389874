import React from "react";
import {useQuery} from "@apollo/client";
import { Container, Row, Col } from "react-bootstrap";
import { window } from "window-or-global";

import Main from "../components/main";
import PortalHeader from "../components/portal/portal-header";
import SEO from "../components/section/seo";
import Loader from "../components/bootstrap/loader";
import Notification from "../components/bootstrap/notification";
import PlanInfromation from "../controls/portal/plan-information";
import Usage from "../controls/portal/usage";
import UserDetails from "../controls/portal/user-details";
import AccountDetails from "../controls/portal/account-details";
import ConnectedDevices from "../controls/portal/connected-devices";
import { getCurrentUserQuery } from "../components/logic/user";
import AcceptTNC from "../components/bootstrap/tnc";

export default function Portal() {
  return (<Main>
    <SEO title="GetGud - Portal" />
    <PortalHeader />
    <PortalIndex />
  </Main>)
}


function PortalIndex(props) {
  const currentUserQuery = useQuery(getCurrentUserQuery);
  if (currentUserQuery.loading) {
    return <Loader />;
  }

  const user = currentUserQuery?.data?.classMethods?.User?.getCurrentUser;

  if (!user) {
    return window.location = "/login";
  }

  return (
    <PortalAccount user={user} {...props} />
  );
}

function PortalAccount({ user }) {
  return (
    <div>
      {(user.requireAcceptTNC) && (<AcceptTNC />)}
      <Container>
        <div>
          <Notification
            overLimit={user.overLimit}
            disabled={user.disabled}
            activeOrders={user.activeOrders}
          />
        </div>
        <Row>
          <Col className="vw-portal-account-title">
            {`Welcome ${user.firstName} ${user.lastName}!`}
          </Col>
        </Row>
        <Row>
          <PlanInfromation
            user={user}
          />
          <Usage user={user} />
        </Row>
        <Row>
          <UserDetails user={user} />
          <AccountDetails user={user} />
          <ConnectedDevices user={user} />
        </Row>
      </Container>
    </div>
  );
}
