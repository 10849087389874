// used for modal forms
import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useAsyncSetState } from "use-async-setstate";

import TextField from "./input";
import FormsyHiddenField from "./hidden";
import { fetch } from "window-or-global";

export default function Captcha({ inline, inputClassName, rowClass, label }) {
  const [captchaKey, setCaptchaKey] = useAsyncSetState();
  const baseUrl = process.env.BACKEND_URL;
  // const txtCaptcha = useRef();

  async function generateCaptcha() {
    if (captchaKey && captchaKey !== "") {
      await fetch(`${baseUrl}captcha.api/destroy/${captchaKey}`);
    }

    const urlGenerate = `${baseUrl}captcha.api/generate`;
    const capGenRes = await fetch(urlGenerate);
    if (capGenRes.status === 200) {
      const { key } = await capGenRes.json();
      await setCaptchaKey(key);
    }
  }

  useEffect(() => {
    generateCaptcha();
  }, []);
  if (!captchaKey) {
    return <React.Fragment />;
  }
  let uri = `${baseUrl}captcha.api/image/${captchaKey}`;

  // function refresh() {
  //   generateCaptcha();
  //   //return setState({...state, date: new Date()});
  // }

  return (
    <>
      <FormsyHiddenField name="captchaKey" val={captchaKey} />
      <Container fluid className="p-0">
        <Row className={`align-items-center ${rowClass || ""}`}>
          <Col xs={12} md={3}>
            <label>
              {"Captcha"}
            </label>
          </Col>
          <Col xs={12} md={inline ? "auto" : 12}>
            <TextField
              name="captcha"
              inputClass={inputClassName || ""}
              type="text"
              fullWidth
              pattern="[a-zA-Z0-9]+"
              inputMode="numeric"
              placeholder="Enter captcha"
              autoComplete="off"
              noLabel
              required
            />
          </Col>
          <Col xs={12} md={inline ? "auto ml-auto" : 12} className="vw-captcha-image">
            <a
              className="vw-button refresh-captcha"
              onClick={async () => await generateCaptcha()}
            >
              <i
                style={{
                  transform: "translateY(20%)",
                  marginRight: 10,
                  cursor: "pointer",
                }}
                className="fa fa-sync"
              />
            </a>
            <img
              alt=""
              key={captchaKey}
              src={uri}
              className="img-fluid pull-left"
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}
